import { motion } from "framer-motion"
import Link from "next/link"
import { useMediaPredicate } from "react-media-hook"
import { useState, useEffect, useRef } from "react"
import Falcon from "@/common/logos/falcon"
import OpenInNew from "@/common/icons/open-in-new"
import styles from "./event.module.css"
import Image from "next/image"

export default function Event({ post }) {
  const isMobile = useMediaPredicate("(max-width: 768px)")
  const [hasMounted, setMounted] = useState(false)
  const [innerHeight, setInnerheight] = useState(100)
  const contentRef = useRef(null)
  const contentMinHeight = 138

  // This is used due to isMobile not working before mount
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      setMounted(true)
      if (contentRef.current) {
        setInnerheight(contentRef.current.firstChild.offsetHeight + 12)
      }
    }
    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!post) {
    return null
  }

  const excerptVariants = {
    initial: {
      translateY: 0,
      transition: {
        duration: 0.2,
        ease: "easeIn"
      }
    },
    hover: {
      translateY: hasMounted && isMobile ? 0 : -innerHeight + contentMinHeight,
      transition: {
        duration: 0.2,
        ease: "easeIn"
      }
    }
  }

  return (
    <motion.div
      className={styles.card}
      initial="initial"
      whileHover="hover"
      animate="initial">
      <Link href={post.url} prefetch={false}>
        <div className="inner-wrapper">
          <div className="header">
            <Falcon />
            <div className="text-wrapper">
              <span className="organizer">{post.organizer}</span>
            </div>
          </div>
          <figure className="image-wrapper">
            <Image
              alt={post.image.alt}
              src={post.image.url}
              width={post.image.width}
              height={post.image.height}
              sizes={post.image.sizes}
            />
          </figure>
          <div
            className="content-wrapper"
            ref={contentRef}
            style={{ minHeight: contentMinHeight }}>
            <motion.div className="motion-elem" variants={excerptVariants}>
              <div className="tag">{post.type.name}</div>
              <h3>{post.title}</h3>
              <p className="dateline">
                <strong>Datum:</strong> {post.dateString} &bull;{" "}
                {post.timeString}
              </p>
              {post.location && (
                <p>
                  <strong>Plats:</strong> {post.location}
                </p>
              )}
              <p className="excerpt">{post.excerpt}</p>
            </motion.div>
          </div>
          <div className="footer-wrapper">
            <span>Läs mer/Boka</span>
            <div className="external">
              <OpenInNew className="w-4" />
            </div>
          </div>
        </div>
      </Link>
    </motion.div>
  )
}
